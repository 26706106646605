import Alpine from 'alpinejs';
import { HTMXEvent } from '../types';
import { NotificationStore } from '@/stores/notification/notification';

export function handleValidationError(response: string) {
    try {
        const { validationError } = JSON.parse(response);

        const toast = Alpine.store('notification') as NotificationStore;
        toast.create({
            type: 'error',
            message: validationError,
        });
    } catch (e) {
        console.error('Failed to parse validation error response', e);
    }
};

export function htmxResponseErrorHandler(event: HTMXEvent, validationErrorHandler: (response: string) => void) {
    const toast = Alpine.store('notification') as NotificationStore;
    const { status: statusCode, response } = event.detail.xhr;

    switch (statusCode) {
        case 400:
            validationErrorHandler(response);
            break;
        default:
            toast.create({
                type: 'error',
                message: window.translation['unexpected_error'],
            });
    }
}

document.addEventListener(
    'htmx:responseError', e => htmxResponseErrorHandler(e as HTMXEvent, handleValidationError)
);
