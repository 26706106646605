import { logErrorIfElementIsMissing } from '../modal_handler/utils';
import ScrollLock from '../scroll_lock/scroll_lock';

class ModalStore {
    scrollLockStore: ScrollLock;
    existingModals: string[];
    visibleModals: Map<string, boolean>;
    uncachedModals: Map<string, boolean>;

    constructor(scrollLock: ScrollLock) {
        this.scrollLockStore = scrollLock;
        this.existingModals = [];
        this.visibleModals = new Map();
        this.uncachedModals = new Map();
    }

    hasExistingModal(id: string): boolean {
        return this.existingModals.includes(id);
    }

    populateUncachedModals(id: string): void {
        this.uncachedModals.set(id, true);
    }

    populateModals(id: string): void {
        this.existingModals.push(id);
    }

    openModal(id: string): void {
        if (!id) {
            logErrorIfElementIsMissing(id);
            return;
        }

        this.scrollLockStore.setBodyScrollable(true);

        this.visibleModals.set(id, true);
    }

    removeFromDom(id: string): void {
        const modalToDelete = document.querySelector(`.${id}`);

        setTimeout(() => {
            modalToDelete.remove();
        }, 250);
    }

    closeModal(id: string): void {
        if (!id) {
            logErrorIfElementIsMissing(id);
            return;
        }

        this.scrollLockStore.setBodyScrollable(false);

        this.visibleModals.delete(id);

        if (this.uncachedModals.has(id)) {
            this.removeFromDom(id);
        }
    }
}

export default ModalStore;
