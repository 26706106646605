import { HTMXEvent } from '../types';
import { loadingHandler } from './handlers/loading';
import { handleModalAfterRequest } from '@/stores/modal/utils';

export function htmxAfterRequestHandler(event: HTMXEvent) {
    loadingHandler(event);
    handleModalAfterRequest(event.target.dataset.targetmodal);
}

document.addEventListener('htmx:afterRequest', htmxAfterRequestHandler);
