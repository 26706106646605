async function initSharedComponents() {
    await import('./shared_components');
}

async function initPageComponent() {
    const pageId = document.body.dataset.pageId;

    switch (pageId) {
        case 'pornstar':
            await import('./pornstar');
            break;
        case 'creator_details':
            await import('./creator_details');
            break;
        case 'edit_creator_account':
            await import('./edit_creator_account');
            break;
        case 'gif_page':
        case 'gif_page_detail':
        case 'gif_subcategory_page':
        case 'gif_newest_page':
            await import('./gif');
            break;
        case 'photo_detail_page':
            await import('./photos/detail');
        case 'photos_landing_page':
        case 'photos_search_page':
        case 'photos_trending_landing_page':
        case 'photos_newest_landing_page':
            await import('./photos/list');
            break;
        case 'identity_verification_info':
            await import('./identity_verification_info');
            break;
        case 'video_page':
            await import('./video');
            break;
        case 'channel_page':
            await import('./channel');
            break;
        case 'home':
            await import('./home');
            break;
        case 'trending':
            await import('./trending');
            break;
    }
}

export { initSharedComponents, initPageComponent };
