const loadingHandler = (dataset: DOMStringMap, loading: boolean) => {
    if (dataset.loadingElement) {
        window.dispatchEvent(
            new CustomEvent('set-loading-element', {
                detail: { target: dataset.loadingElement, loading },
            })
        );
    }
};

export { loadingHandler };
