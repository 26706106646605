import { getScrollLockStore } from '../scroll_lock';
import { getModalStore } from './index';

export const handleModalState = (id?: string, cache?: string): boolean => {
    if (!id) return false;

    const modalStore = getModalStore(getScrollLockStore());

    if (cache == null) {
        modalStore.populateUncachedModals(id);
        return false;
    }

    if (modalStore.hasExistingModal(id)) {
        modalStore.openModal(id);
        return true;
    }

    return false;
};

export const handleModalAfterRequest = (id: string | null | undefined): void => {
    if (!id) {
        return;
    }

    const modalStore = getModalStore(getScrollLockStore());

    modalStore.populateModals(id);
    modalStore.openModal(id);
};
