export const logErrorIfElementIsMissing = (id: string) => {
    console.error(`Element with data-id: ${id} not found`);
};

export const populateModalObject = (modalOverlayElements: NodeListOf<HTMLElement>) => {
    const obj: Record<string, { isVisible: boolean }> = {};

    modalOverlayElements.forEach((element) => {
        if (!element.dataset.id) {
            console.error('Attribute: data-id is missing');
            return;
        }

        let id = element.dataset.id;
        obj[id] = { isVisible: false };
    });

    return obj;
};
