import Alpine from 'alpinejs';
import ModalStore from './modal';
import ScrollLock from '../scroll_lock/scroll_lock';

export const getModalStore = (scrollLock: ScrollLock): ModalStore => {
    if (!Alpine.store('modal')) {
        Alpine.store('modal', new ModalStore(scrollLock));
    }

    return Alpine.store('modal') as ModalStore;
};
