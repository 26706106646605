import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import DOMPurify from 'dompurify';

import 'htmx.org';

import '@/events';

import { initPageComponent, initSharedComponents } from './load_components';

async function init() {
    window.Alpine = Alpine;
    window.DOMPurify = DOMPurify; // TODO: remove once DOMPurify is used only in modules

    await initSharedComponents();
    await initPageComponent();

    Alpine.plugin(intersect);
    Alpine.start();
}

init();
