import { handleModalState } from '@/stores/modal/utils';
import { HTMXEvent } from '@/events/types';

export function htmxConfirmHandler(event: HTMXEvent) {
    event.preventDefault();

    const feature = event.target.dataset.feature;

    if (feature) {
        // @TODO: Implement check_login with the new stack
        if (!window.check_login(feature)) return;
    }

    if (handleModalState(event.target.dataset.targetmodal, event.target.dataset.cache)) return;

    event.detail.issueRequest();
}

document.addEventListener('htmx:confirm', htmxConfirmHandler);
